<template>
  <div v-if="pasien">
    <v-row>
      <v-col>
        <v-card elevation="3" shaped>
          <v-card-title>Informasi Pemeriksaan</v-card-title>
          <v-card-subtitle>Informasi umum tentang Laboratorium</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <h1 class="subtitle-1">Request Lab</h1>
                <p class="title black--text">{{ pasien.requestLab }}</p>
                <h1 class="subtitle-1">Location</h1>
                <p class="title black--text">{{ pasien.location }}</p>
                <h1 class="subtitle-1">Sampling Date</h1>
                <p class="title black--text">
                  {{ localDate(pasien.samplingDate) }}
                </p>
              </v-col>
              <v-col>
                <h1 class="subtitle-1">Sample Receive From</h1>
                <p class="title black--text">
                  {{ pasien.sampleSender }}
                </p>
                <h1 class="subtitle-1">Sample Received Date</h1>
                <p class="title black--text">
                  {{ localDate(pasien.receiveSampleDate) }}
                </p>
                <h1 class="subtitle-1">Result Date</h1>
                <p class="title black--text">
                  {{ localDate(pasien.resultDate) }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="3" shaped>
          <v-card-title>Informasi Pasien</v-card-title>
          <v-card-subtitle>Informasi umum tentang pasien</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <h1 class="subtitle-1">Name</h1>
                <p class="title black--text">{{ pasien.name }}</p>
                <h1 class="subtitle-1">Birth Date</h1>
                <p class="title black--text">
                  {{ localDate(pasien.birthDate) }} ({{
                    yearOld(pasien.birthDate)
                  }}
                  tahun)
                </p>
                <h1 class="subtitle-1">Gender</h1>
                <p class="title black--text">{{ pasien.gender }}</p>
                <h1 class="subtitle-1">Identity Number</h1>
                <p class="title black--text">{{ pasien.identityNumber }}</p>
                <h1 class="subtitle-1">Passport Number</h1>
                <p class="title black--text">{{ pasien.passportNumber }}</p>
              </v-col>
              <v-col>
                <h1 class="subtitle-1">Phone Number</h1>
                <p class="title black--text">{{ pasien.phoneNumber || "-" }}</p>
                <h1 class="subtitle-1">Email</h1>
                <p class="title black--text">{{ pasien.email || "-" }}</p>
                <h1 class="subtitle-1">Address</h1>
                <p class="title black--text">{{ pasien.address }}</p>
                <h1 class="subtitle-1">Nationality</h1>
                <p class="title black--text">
                  {{ pasien.nationality }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="3" shaped>
          <v-card-title>Laboratory Test</v-card-title>
          <v-card-subtitle>Laboratory Test Result</v-card-subtitle>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">INFECTION</th>
                    <th class="text-left">RESULT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="pasien.pcrResult != null">
                    <td class="font-weight-bold">PCR SARS-CoV-2</td>
                    <td class="font-weight-black">{{ pasien.pcrResult }}</td>
                  </tr>
                  <tr v-if="pasien.antigenResult != null">
                    <td class="font-weight-bold">ANTIGEN SARS-CoV-2</td>
                    <td class="font-weight-black">
                      {{ pasien.antigenResult }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="print" class="primary" block>PRINT</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    pasien() {
      return this.$store.getters.pasien;
    },
  },
  methods: {
    print() {
      this.$store.dispatch("getPasienForPrint", [this.pasien]);
      this.$router.push(`/print`);
    },
    localDate(date) {
      date = new Date(date);
      return Intl.DateTimeFormat("id-ID", { dateStyle: "long" }).format(date);
    },
    yearOld(date) {
      date = new Date(date);
      let today = new Date();
      let yearOld = today.getFullYear() - date.getFullYear();
      return yearOld;
    },
  },
};
</script>
